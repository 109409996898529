import "./ImageWithCaption.css";

interface ImageProps {
  imagePath: string;
  imageAlt: string;
  imageCaption: string;
  imageCredits: string;
}

const ImageWithCaption: React.FC<ImageProps> = ({
  imagePath,
  imageAlt,
  imageCaption,
  imageCredits,
}) => {
  return (
    <div className="image-with-caption-wrapper">
      <div className="image-wrapper">
        <img className="image-content" src={imagePath} alt={imageAlt} />
        <span className="image-credits">{imageCredits}</span>
      </div>
      <span className="image-caption">{imageCaption}</span>
    </div>
  );
};

export default ImageWithCaption;
