import ImageWithCaption from "../ui/ImageWithCaption";
import "./StrugglePage.css";
import catImage1 from "../../assets/cats/stray-cat-31.jpeg";
import catImage2 from "../../assets/cats/stray-cat-32.jpeg";
import catImage3 from "../../assets/cats/stray-cat-34.jpeg";
import catImage4 from "../../assets/cats/stray-cat-35.jpeg";
import { Link } from "react-router-dom";

const StrugglePage: React.FC = () => {
  return (
    <div className="struggle-page-wrapper">
      <div className="struggle-page-content">
        <h1 className="struggle-page-header">Struggles of Community Cats</h1>
        <h2 className="struggle-page-subheader struggle-page-subheader-first">
          Community cats are vulnerable to illnesses and injuries in many ways
        </h2>
        <div className="struggle-page-image-container">
          <ImageWithCaption
            imagePath={catImage1}
            imageAlt="A cat living inside a drain"
            imageCaption="A community cat living in a drain after being chased away from her regular territory."
            imageCredits="@toapayohnorth_cats"
          />
          <ImageWithCaption
            imagePath={catImage2}
            imageAlt="An injured cat at the vet"
            imageCaption="A senior community cat who succumbed to her injuries after a suspected road accident."
            imageCredits="@toapayohnorth_cats"
          />
        </div>
        <p className="struggle-page-content-header">
          Attacks from other animals
        </p>
        <p className="struggle-page-text">
          Community cats are at risk of being attacked by other animals, such as
          dogs or snakes. Between 2022 and 2023, more than 30 community cats
          were{" "}
          <a
            className="struggle-page-text struggle-page-link"
            rel="noreferrer noopener"
            target="_blank"
            href="https://www.channelnewsasia.com/singapore/stray-dogs-community-cats-attacked-mauled-ang-mo-kio-3269006"
          >
            mauled by stray dogs
          </a>
          . As cats are highly territorial, cats that are unfamiliar with one
          another may also fight, resulting in injuries and the transmission of
          diseases like Feline Immunodeficiency Virus (FIV) and Feline Leukemia
          Virus (FeLV).
        </p>
        <p className="struggle-page-content-header">Abuse by humans</p>
        <p className="struggle-page-text">
          Community cats may fall victim to abuse by those who view them as
          pests or easy targets. In some instances, complaints from residents
          about community cats lead to them being{" "}
          <a
            className="struggle-page-text struggle-page-link"
            rel="noreferrer noopener"
            target="_blank"
            href="https://www.straitstimes.com/singapore/town-council-apologises-for-the-way-it-handled-relocation-of-cat-that-was-placed-in-bin"
          >
            forcefully captured or relocated
          </a>{" "}
          to unfamiliar environments. Other individuals may go out of their way
          to{" "}
          <a
            className="struggle-page-text struggle-page-link"
            rel="noreferrer noopener"
            target="_blank"
            href="https://www.channelnewsasia.com/singapore/ang-mo-kio-cat-slasher-jailed-animal-cruelty-says-cats-are-not-human-2017046"
          >
            deliberately harm community cats
          </a>
          . There are many instances of cat abuse, some of which are documented
          on the{" "}
          <Link to="/map?mapType=catAbuse">
            <span className="struggle-page-text struggle-page-link">
              abuse map
            </span>
          </Link>
          .
        </p>
        <p className="struggle-page-content-header">
          Dangers of urban environment
        </p>
        <p className="struggle-page-text">
          Without natural sources of food, community cats may attempt to find
          food and water in garbage or drains, putting them at risk of illness.
          Community cats are also highly susceptible to{" "}
          <a
            className="struggle-page-text struggle-page-link"
            rel="noreferrer noopener"
            target="_blank"
            href="https://mothership.sg/2021/02/cat-chase-birds-hit-by-car/"
          >
            road accidents
          </a>
          , and many cats suffer very painful injuries and deaths every month.
        </p>
        <h2 className="struggle-page-subheader">
          While volunteer caregivers do their best to care for community cats,
          they are limited by many factors.
        </h2>
        <div className="struggle-page-image-container">
          <ImageWithCaption
            imagePath={catImage3}
            imageAlt="cat"
            imageCaption="Multiple abadoned kittens found inside a cardboard box"
            imageCredits=""
          />
          <ImageWithCaption
            imagePath={catImage4}
            imageAlt="cat"
            imageCaption="Community cats being fed by a volunteer caregiver"
            imageCredits="@toapayohnorth_cats"
          />
        </div>
        <p className="struggle-page-content-header">Large cat population</p>
        <p className="struggle-page-text">
          Singapore is home to{" "}
          <a
            className="struggle-page-text struggle-page-link"
            rel="noreferrer noopener"
            target="_blank"
            href="https://www.straitstimes.com/singapore/islandwide-plan-to-sterilise-stray-cats"
          >
            approximately 50000 stray cats
          </a>
          , which significantly exceeds the number of volunteer caregivers. Many
          cats are fearful of humans and will hide from volunteer caregivers,
          making it especially difficult to sterilise or care for them.
          Unfortunately, many cats die due to sickness or injury before they are
          discovered by a volunteer caregiver.
        </p>
        <p className="struggle-page-content-header">Limited resources</p>
        <p className="struggle-page-text">
          Providing food and medical care for a single community cat can cost
          thousands of dollars. Volunteer caregivers often{" "}
          <a
            className="struggle-page-text struggle-page-link"
            rel="noreferrer noopener"
            target="_blank"
            href="https://mothership.sg/2021/04/amk-cat-feeder/"
          >
            bear these expenses out of their own pockets
          </a>{" "}
          due to insufficient donations. Caring for community cats is also time
          consuming, and volunteer caregivers dedicate their time to feeding and
          monitoring community cats despite their personal and professional
          commitments. Nonetheless, as their resources are finite, they cannot
          possibly care for every community cat.
        </p>
        <p className="struggle-page-content-header">Burnout</p>
        <p className="struggle-page-text">
          Being a volunteer caregiver can be very financially and emotionally
          overwhelming. The prevalence of{" "}
          <a
            className="struggle-page-text struggle-page-link"
            rel="noreferrer noopener"
            target="_blank"
            href="https://www.channelnewsasia.com/singapore/pets-abandoned-dogs-cats-inflation-costs-vets-3251206"
          >
            cat abandonment
          </a>
          , including cases of{" "}
          <a
            className="struggle-page-text struggle-page-link"
            rel="noreferrer noopener"
            target="_blank"
            href="https://www.straitstimes.com/singapore/more-than-400-cats-found-in-cases-of-pet-hoarding-as-rescuers-plead-burnout"
          >
            hoarders
          </a>{" "}
          and illegal breeders abandoning more than 10 cats at a time, places
          great strain on volunteer caregivers. Moreover, volunteer caregivers
          often worry about the safety of their community cats who go{" "}
          <a
            className="struggle-page-text struggle-page-link"
            rel="noreferrer noopener"
            target="_blank"
            href="https://mothership.sg/2023/03/community-cat-missing-brutal-dies/"
          >
            missing
          </a>
          , and{" "}
          <a
            className="struggle-page-text struggle-page-link"
            rel="noreferrer noopener"
            target="_blank"
            href="https://sg.news.yahoo.com/stray-cats-fed-cat-feeder-100328145.html"
          >
            endure harassment
          </a>{" "}
          from residents who view cats as pests. Consequently, volunteer
          caregivers often experience{" "}
          <a
            className="struggle-page-text struggle-page-link"
            rel="noreferrer noopener"
            target="_blank"
            href="https://www.todayonline.com/singapore/community-cat-feeders-2082656"
          >
            high levels of stress
          </a>{" "}
          and burnout.
        </p>
      </div>
    </div>
  );
};

export default StrugglePage;
