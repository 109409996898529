import { Link } from "react-router-dom";
import "../pages/HelpPage.css";

const Feed: React.FC = () => {
  return (
    <>
      <p className="help-page-text">
        If you wish to feed community cats, please take note of the following:
      </p>
      <br />
      <h3 className="help-page-subheader">Check for regular feeders</h3>
      <p className="help-page-text">
        If a cat appears to be well-fed, it likely has a regular feeder. By
        choosing to feed the cat, you may disrupt its regular feeding schedule
        and contribute to overfeeding. If you are unsure if a cat has a regular
        feeder, consider asking in the relevant Facebook groups, some of which
        are listed{" "}
        <Link to="/donate">
          <span className="help-page-underline">here</span>
        </Link>
        .
      </p>
      <br />
      <h3 className="help-page-subheader">Use containers</h3>
      <p className="help-page-text">
        When feeding a community cat, use containers for cat food and clean
        water. Feeding food directly from a can is dangerous as the cat may cut
        their tongue on the sharp edges. In addition, placing food directly on
        the ground is unhygienic and will attract pests. As such, it is best to
        use a clean container (e.g. a plastic bowl) when feeding cats.
      </p>
      <br />
      <h3 className="help-page-subheader">Clean up</h3>
      <p className="help-page-text">
        After feeding the cat, please clear up the remaining food and water
        immediately. Leaving food out for longer than an hour causes it to spoil
        and attracts pests, while leaving water out encourages mosquito
        breeding. This burdens volunteer caregivers and cleaners who have to
        clean up after your mess, and incites more complaints against community
        cats that might result in them being forcefully captured or relocated.
        By failing to clean up, you may also be committing a crime by littering
        the environment.
      </p>
      <br />
      <p className="help-page-text">
        Following these guidelines is crucial in ensuring the safety and
        wellbeing of all community cats.
      </p>
    </>
  );
};

export default Feed;
