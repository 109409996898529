import React from "react";
import { Link } from "react-router-dom";
import "./Button.css";

interface ButtonProps {
  buttonText: string;
  internalNav: boolean;
  navTo?: string;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  buttonText,
  internalNav,
  navTo,
  onClick,
}) => {
  return internalNav ? (
    <div className="button-wrapper">
      <Link to="/map">
        <p className="button-text">{buttonText}</p>
      </Link>
    </div>
  ) : (
    <div className="button-wrapper" onClick={onClick}>
      <p className="button-text">{buttonText}</p>
    </div>
  );
};

export default Button;
