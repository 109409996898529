import { Link } from "react-router-dom";
import "./FaqPage.css";

const FaqPage: React.FC = () => {
  return (
    <div className="faq-page-wrapper">
      <div className="faq-page-content">
        <h2 className="faq-page-header">How does our map work?</h2>
        <div className="faq-page-answer">
          <p className="faq-page-subheader">Data collection</p>
          We use an{" "}
          <span className="faq-page-link">
            <Link to="/submit">online form</Link>
          </span>{" "}
          to crowd-source data about community cats. Every submission is
          manually checked to ensure it does not contain spam or inappropriate
          content before being displayed on our map. This approval process could
          take up to 5 working days.
          <br />
          <br />
          <p className="faq-page-subheader">Cat safety</p>
          <span className="faq-page-emphasis">
            We do not collect or display the exact location of any community
            cat.{" "}
          </span>
          Instead, we only collect information about the community they belong
          to (e.g. Bedok). Our platform then uses this community information to
          determine the{" "}
          <a
            className="faq-page-link"
            href="https://en.wikipedia.org/wiki/Regions_of_Singapore"
            rel="noreferrer noopener"
            target="_blank"
          >
            broader region
          </a>{" "}
          the cat belongs to, and chooses a random location within the
          boundaries of this broader region to position the cat on our map. This
          random location is regenerated every time the map is refreshed. At the
          same time, we also crop and/or blur the background of any image that
          could give away the location of the cat. In this manner, our map does
          not reveal the community or exact location of the cat. By doing so, we
          ensure that malicious users cannot use our map to find and bring harm
          to any cat.
          <br />
          <br />
          <p className="faq-page-subheader">Contact</p>
          For any feedback, suggestions, or collaborations, please{" "}
          <a className="faq-page-link" href="mailto:catsofsg.contact@gmail.com">
            email us
          </a>
          .
        </div>
      </div>
    </div>
  );
};

export default FaqPage;
