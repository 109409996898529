import { useState } from "react";
import { NavLink } from "react-router-dom";
import openMenu from "../../assets/icons/open-menu.png";
import closeMenu from "../../assets/icons/close-menu.png";
import "./MobileNavbar.css";
const MobileNavbar: React.FC = () => {
  const [openNavbar, setOpenNavbar] = useState<boolean>(false);

  return (
    <>
      <div className="mobile-navbar-wrapper">
        <div className="mobile-navbar-logo">
          <NavLink to="/">Cats of SG</NavLink>
        </div>
        {!openNavbar ? (
          <img
            className="mobile-navbar-button-icon"
            onClick={() => setOpenNavbar(!openNavbar)}
            src={openMenu}
            alt="open menu"
          />
        ) : (
          <img
            className="mobile-navbar-button-icon"
            onClick={() => setOpenNavbar(!openNavbar)}
            src={closeMenu}
            alt="close menu"
          />
        )}
      </div>
      <div
        className={
          openNavbar
            ? "mobile-navbar-buttons-container mobile-navbar-show"
            : "mobile-navbar-buttons-container mobile-navbar"
        }
      >
        <div className="mobile-navbar-button">
          <NavLink to="/map">Map</NavLink>
        </div>
        <div className="mobile-navbar-button">
          <NavLink to="/about">About</NavLink>
        </div>
        <div className="mobile-navbar-button">
          <NavLink to="/struggles">Struggles</NavLink>
        </div>
        <div className="mobile-navbar-button">
          <NavLink to="/help">Help</NavLink>
        </div>
        <div className="mobile-navbar-button">
          <NavLink to="/submit">Submit</NavLink>
        </div>
      </div>
    </>
  );
};

export default MobileNavbar;
