import { Link } from "react-router-dom";
import catImage from "../../assets/cats/stray-cat-4.jpg";
import "./UnknownPage.css";

const UnknownPage: React.FC = () => {
  return (
    <div className="unknown-page-wrapper">
      <div className="unknown-page-content">
        <h1 className="unknown-page-headline">This page does not exist!</h1>

        <img className="unknown-page-image" src={catImage} alt="sad cat"></img>

        <div className="unknown-page-instructions">
          <Link to="/">Go back home</Link>
        </div>
      </div>
    </div>
  );
};

export default UnknownPage;
