import { useState } from "react";
import Loading from "../ui/Loading";
import { CatInterface } from "../map/Marker";
import SiteError from "../ui/SiteError";
import "./CatDetail.css";
import { toTitleCase, formatPara } from "../../util/stringUtil";
import { AbusedCatInterface } from "./NewMapPopup";
import { Helmet } from "react-helmet";

enum State {
  LOADING,
  LOADED,
  ERROR,
}

interface CatDetailProps {
  catData: CatInterface | AbusedCatInterface;
  mapType: "cats" | "catAbuse";
  backFunction: () => void;
}

const CatDetail: React.FC<CatDetailProps> = ({
  catData,
  mapType,
  backFunction,
}) => {
  const [state, setState] = useState<State>(State.LOADED);
  const [showImage, setShowImage] = useState<boolean>(
    "graphicImage" in catData ? !catData.graphicImage : true
  );

  const render = () => {
    switch (state) {
      case State.LOADING:
        return <Loading />;
      case State.LOADED:
        return (
          <>
            <Helmet>
              <title>{`${
                catData?.name || "Community cat"
              } - Cats of SG`}</title>
              <link
                rel="canonical"
                href={`https://catsofsg.com/map?mapType=${mapType}&catId=${catData.catId}`}
              />
              <meta
                name="description"
                content={`Name: ${catData.name}. Region: ${toTitleCase(
                  catData?.region
                )}. Sex: ${toTitleCase(catData.sex)}.${
                  "incident" in catData ? ` Abuse: ${catData.incident}.` : ""
                }${
                  "incidentDescription" in catData &&
                  catData.incidentDescription
                    ? ` Abuse description: ${catData.incidentDescription}.`
                    : ""
                }${
                  "incidentSocialLink" in catData && catData.incidentSocialLink
                    ? ` Media link: ${catData.incidentSocialLink}.`
                    : ""
                }${catData.likes ? ` Likes: ${catData.likes}.` : ""}${
                  catData.dislikes ? ` Dislikes: ${catData.dislikes}.` : ""
                }${
                  catData.personality
                    ? ` Personality: ${catData.personality}.`
                    : ""
                }${
                  catData.other ? ` Other information: ${catData.other}.` : ""
                }`}
              />
            </Helmet>
            <div className="cat-detail-wrapper">
              <span className="cat-detail-back" onClick={backFunction}>
                &#8592;{" Back to Map"}
              </span>
              <div className="cat-detail-content">
                <div className="cat-detail-image-wrapper">
                  <img
                    // onClick={() => setShowImage(true)}
                    className={
                      showImage
                        ? "cat-detail-image"
                        : "cat-detail-image cat-detail-image-blur"
                    }
                    src={catData?.imageUrl}
                    onError={() => setState(State.ERROR)}
                    alt="community cat"
                  />
                  <span
                    className={
                      showImage
                        ? "cat-detail-image-warning cat-detail-hidden"
                        : "cat-detail-image-warning"
                    }
                    onClick={() => setShowImage(true)}
                  >
                    Graphic content: Click to view.
                  </span>
                </div>
                <div className="cat-detail-text-wrapper">
                  <span className="cat-detail-subheader">
                    Basic Information
                  </span>
                  <div className="cat-detail-item">
                    {catData?.name ? (
                      <span>
                        {"Name: "}
                        <span className="cat-detail-field">
                          {catData?.name}
                        </span>
                      </span>
                    ) : null}
                  </div>
                  <div className="cat-detail-item">
                    {catData?.sex ? (
                      <span>
                        {"Sex: "}
                        <span className="cat-detail-field">
                          {toTitleCase(catData?.sex)}
                        </span>
                      </span>
                    ) : null}
                  </div>
                  <div className="cat-detail-item">
                    {catData?.region ? (
                      <span>
                        {"Region: "}
                        <span className="cat-detail-field">
                          {toTitleCase(catData?.region)}
                        </span>
                      </span>
                    ) : null}
                  </div>
                  {mapType === "catAbuse" ? (
                    <>
                      <br />
                      <span className="cat-detail-subheader">
                        Incident Details
                      </span>
                      <div className="cat-detail-item">
                        {(catData as AbusedCatInterface)?.incident ? (
                          <span>
                            {"Incident Type: "}
                            <span className="cat-detail-field">
                              {(catData as AbusedCatInterface)?.incident}
                            </span>
                          </span>
                        ) : null}
                      </div>
                      <div className="cat-detail-item">
                        {(catData as AbusedCatInterface)
                          ?.incidentDescription ? (
                          <span>
                            {"Incident Description: "}
                            <span className="cat-detail-field">
                              {
                                (catData as AbusedCatInterface)
                                  ?.incidentDescription
                              }
                            </span>
                          </span>
                        ) : null}
                      </div>
                      <div className="cat-detail-item">
                        {(catData as AbusedCatInterface)?.incidentSocialLink ? (
                          <span>
                            {"Media Link: "}
                            <span className="cat-detail-field">
                              <a
                                className="cat-detail-field cat-detail-link"
                                rel="noreferrer noopener"
                                target="_blank"
                                href={
                                  (catData as AbusedCatInterface)
                                    ?.incidentSocialLink
                                }
                              >
                                {
                                  (catData as AbusedCatInterface)
                                    ?.incidentSocialLink
                                }
                              </a>
                            </span>
                          </span>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                  {(catData.likes ||
                    catData.dislikes ||
                    catData.personality ||
                    catData.other) && (
                    <>
                      <br />
                      <span className="cat-detail-subheader">
                        Other Information
                      </span>
                    </>
                  )}
                  <div className="cat-detail-item">
                    {catData?.likes ? (
                      <span>
                        {"Likes: "}
                        <span className="cat-detail-field">
                          {formatPara(catData?.likes)}
                        </span>
                      </span>
                    ) : null}
                  </div>
                  <div className="cat-detail-item">
                    {catData?.dislikes ? (
                      <span>
                        {"Dislikes: "}
                        <span className="cat-detail-field">
                          {formatPara(catData?.dislikes)}
                        </span>
                      </span>
                    ) : null}
                  </div>
                  <div className="cat-detail-item">
                    {catData?.personality ? (
                      <span>
                        {"Personality: "}
                        <span className="cat-detail-field">
                          {formatPara(catData?.personality)}
                        </span>
                      </span>
                    ) : null}
                  </div>
                  <div className="cat-detail-item">
                    {catData?.other ? (
                      <span>
                        {"Other Information: "}
                        <span className="cat-detail-field">
                          {formatPara(catData?.other)}
                        </span>
                      </span>
                    ) : null}
                  </div>
                  <p className="cat-detail-edit">
                    If you wish to add, modify, or remove any information for
                    this cat, please{" "}
                    <a
                      className="cat-detail-link"
                      href="mailto:catsofsg.contact@gmail.com"
                    >
                      contact us
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      case State.ERROR:
        return <SiteError />;
      default:
        return null;
    }
  };

  return render();
};

export default CatDetail;
