import { useState } from "react";
import "./Collapsible.css";
import expandArrow from "../../assets/icons/expand-arrow-50.png";
import collapseArrow from "../../assets/icons/collapse-arrow-50.png";

interface CollapsibleProps {
  header: string;
  content: JSX.Element;
}

const Collapsible: React.FC<CollapsibleProps> = ({ header, content }) => {
  const [showContent, setShowContent] = useState<boolean>(false);

  return (
    <div className="collapsible-wrapper">
      <div
        className="collapsible-top"
        onClick={() => setShowContent(!showContent)}
      >
        <span className="collapsible-top-text">{header}</span>
        {showContent ? (
          <img
            className="collapsible-top-icon"
            src={collapseArrow}
            alt="collapse"
          />
        ) : (
          <img
            className="collapsible-top-icon"
            src={expandArrow}
            alt="expand"
          />
        )}
      </div>
      <div
        className={
          showContent
            ? "collapsible-bottom"
            : "collapsible-bottom collapsible-hidden"
        }
      >
        {content}
      </div>
    </div>
  );
};

export default Collapsible;
