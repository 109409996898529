import React, { useRef, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";

import "./NewMap.css";
import {
  REACT_APP_CAT_API_URL,
  REACT_APP_MAPBOX_ACCESS_TOKEN,
} from "../../util/config";
import axios from "axios";
import {
  getRandomPoint,
  getRegionBounds,
  SG_BOUNDS,
  SG_CENTER_COORDS,
  SG_REGION_AREA_DATA,
} from "../../util/mapUtil";
import { toTitleCase } from "../../util/stringUtil";
import Popup from "../ui/Popup";
import NewMapPopup from "./NewMapPopup";
import SiteError from "../ui/SiteError";
import Loading from "../ui/Loading";
import mapIcon1 from "../../assets/icons/map-icon-1.png";
import mapIcon3 from "../../assets/icons/map-icon-3.png";
import mapIcon4 from "../../assets/icons/map-icon-4.png";
import mapIcon5 from "../../assets/icons/map-icon-5.png";

import CatDetail from "./CatDetail";
import { Link, useSearchParams } from "react-router-dom";
import { CatInterface } from "./Marker";
import { AbusedCatInterface } from "./abuse/AbuseMapPopup";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
// import maplibregl from "!maplibre-gl"; // ! is important here
// //@ts-ignore
// import maplibreglWorker from "maplibre-gl/dist/maplibre-gl-csp-worker";

// // eslint-disable-next-line import/first
// import {
//   LngLatBoundsLike,
//   LngLatLike,
//   Map as LibreMap,
//   Marker as LibreMarker,
// } from "maplibre-gl";
// // eslint-disable-next-line import/first
// import "maplibre-gl/dist/maplibre-gl.css";

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
// eslint-disable-next-line import/first
import "mapbox-gl/dist/mapbox-gl.css";

// eslint-disable-next-line import/first
import {
  LngLatBoundsLike,
  LngLatLike,
  Map as MapBoxMap,
  Marker as MapBoxMarker,
} from "mapbox-gl";

// //@ts-ignore
// maplibregl.workerClass = maplibreglWorker;

export const incidentType = [
  "Beaten",
  "Stabbed",
  "Slashed",
  "Dismembered",
  "Burned",
  "Thrown from height",
  "Poisoned",
  "Glued",
  "Tied",
  "Hit and run",
  "Injured (unconfirmed cause)",
  "Other",
] as const;

export type IncidentType = (typeof incidentType)[number];

enum State {
  LOADING,
  LOADED,
  ERROR,
}

interface AbusedCatPreviewInterface {
  catId: string;
  region: string;
  name: string;
  dateModified: number;
  incident: IncidentType;
}

interface CatPreviewInterface {
  catId: string;
  region: string;
  name: string;
  dateModified: number;
}

interface NewMapProps {
  mapType: "cats" | "catAbuse";
  hidden: boolean;
}

const NewMap: React.FC<NewMapProps> = ({ hidden, mapType }) => {
  const mapContainer = useRef<any>();
  const map = useRef<MapBoxMap>();
  const [selectedCommunity, setSelectedCommunity] = useState<string>("All");
  const [selectedName, setSelectedName] = useState<string>("");
  const [selectedIncidentType, setSelectedIncidentType] =
    useState<string>("All");
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const [libreMarkers] = useState<{ [catId: string]: MapBoxMarker }>({});
  const [state, setState] = useState<State>(State.LOADING);

  const [selectedCatMarkers, setSelectedCatMarkers] = useState<
    CatPreviewInterface[] | AbusedCatPreviewInterface[]
  >([]);

  const [catMarkers, setCatMarkers] = useState<
    CatPreviewInterface[] | AbusedCatPreviewInterface[]
  >([]);

  const [selectedCat, setSelectedCat] = useState<
    CatInterface | AbusedCatInterface
  >();

  const [searchParams, setSearchParams] = useSearchParams({});

  const chooseIcon = () => {
    if (mapType === "cats") {
      const rand = Math.random();
      if (rand < 0.33) {
        return mapIcon1;
      } else if (rand < 0.66) {
        return mapIcon3;
      } else {
        return mapIcon4;
      }
    } else {
      return mapIcon5;
    }
  };

  const scrollFunction = (catData: CatInterface | AbusedCatInterface) => {
    setSelectedCat(catData);
    searchParams.set("mapType", mapType);
    searchParams.set("catId", catData.catId);
    setSearchParams(searchParams);
  };

  const backFunction = () => {
    searchParams.delete("catId");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (
      !(
        process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ||
        REACT_APP_MAPBOX_ACCESS_TOKEN
      ) ||
      !(process.env.REACT_APP_CAT_API_URL || REACT_APP_CAT_API_URL)
    ) {
      setState(State.ERROR);
    }
  }, []);

  useEffect(() => {
    if (map.current) return;
    mapboxgl.accessToken =
      process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ||
      REACT_APP_MAPBOX_ACCESS_TOKEN;
    map.current = new mapboxgl.Map({
      container: mapContainer.current!,
      style: `mapbox://styles/knowyourenemy/cld8rbx13000b01moux1wbyv1`,

      center: SG_CENTER_COORDS as LngLatLike,
      bounds: SG_BOUNDS as LngLatBoundsLike,
      minZoom: 8,
      maxZoom: 16,
    });
    map.current?.on("load", () => {
      axios
        .get(
          `${process.env.REACT_APP_CAT_API_URL || REACT_APP_CAT_API_URL}/api/${
            mapType === "cats" ? "cats" : "abusedCats"
          }`
        )
        .then((res) => {
          if (res.status !== 200) {
            setState(State.ERROR);
          } else {
            const apiData: CatPreviewInterface[] | AbusedCatPreviewInterface[] =
              res.data;
            const apiDataWithLocations = apiData.map((data) => {
              const randomPosition = getRandomPoint(data.region);
              return {
                ...data,
                position: {
                  lat: randomPosition[1],
                  lng: randomPosition[0],
                },
              };
            });

            apiDataWithLocations.forEach((point) => {
              const markerIcon = document.createElement("div");
              markerIcon.style.width = "30px";
              markerIcon.style.height = "30px";
              markerIcon.style.backgroundSize = "contain";
              markerIcon.style.backgroundImage = `url(${chooseIcon()})`;
              markerIcon.id = point.catId;
              markerIcon.style.objectFit = "cover";
              markerIcon.style.cursor = "pointer";

              markerIcon.addEventListener("click", (e) => {
                const target = e!.target as HTMLDivElement;
                const id = target.id;
                if (!libreMarkers[id].getPopup()) {
                  const placeholder = document.createElement("div");
                  createRoot(placeholder).render(
                    <NewMapPopup
                      catId={point.catId}
                      onClickScroll={scrollFunction}
                      mapType={mapType}
                    />
                  );
                  const popup = new mapboxgl.Popup({
                    closeOnClick: false,
                    offset: [0, -15],
                  });
                  popup.on("close", () => setSelectedCat(undefined));

                  popup.setDOMContent(placeholder);
                  libreMarkers[id].setPopup(popup);
                }
              });

              const marker = new MapBoxMarker(markerIcon, {
                anchor: "bottom",
                rotationAlignment: "map",
              })
                .setLngLat([point.position.lng, point.position.lat])
                .addTo(map.current!);

              libreMarkers[point.catId] = marker;
            });

            setCatMarkers(apiDataWithLocations);
            setSelectedCatMarkers(apiDataWithLocations);
            if (state === State.LOADING) {
              setState(State.LOADED);
            }
          }
        })
        .catch((e) => {
          setState(State.ERROR);
        });
    });
    map.current?.on("error", () => {
      setState(State.ERROR);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!hidden) {
      if (searchParams.get("catId")) {
        if (
          searchParams.get("mapType") === mapType &&
          searchParams.get("catId") !== selectedCat?.catId
        ) {
          axios
            .get(
              `${
                process.env.REACT_APP_CAT_API_URL || REACT_APP_CAT_API_URL
              }/api/${
                mapType === "cats" ? "cats" : "abusedCats"
              }/${searchParams.get("catId")}`
            )
            .then((res) => {
              setSelectedCat(res.data);
            })
            .catch((e) => {
              setState(State.ERROR);
            });
        }
      } else {
        setSelectedCat(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, hidden]);

  useEffect(() => {
    if (!selectedCat) {
      map.current?.resize();
    }
  }, [selectedCat]);

  const resetFilter = (e: React.MouseEvent) => {
    e.preventDefault();
    setSelectedCat(undefined);
    setSelectedCommunity("All");
    setSelectedName("");
    mapType === "catAbuse" && setSelectedIncidentType("All");
    setSelectedCatMarkers(catMarkers);
    map.current?.fitBounds(SG_BOUNDS as LngLatBoundsLike);
    Object.keys(libreMarkers).forEach((catId) => {
      // if (!libreMarkers[catId].) {
      libreMarkers[catId].addTo(map.current!);
      // }
    });
  };

  const applyFilter = (e: React.FormEvent) => {
    e.preventDefault();
    setSelectedCat(undefined);
    const filteredCatMarkers = catMarkers.filter((marker) => {
      var communityMatch = true;
      var nameMatch = true;
      var incidentTypeMatch = true;
      if (selectedCommunity && selectedCommunity !== "All") {
        communityMatch = marker.region === selectedCommunity;
      }
      if (selectedName) {
        nameMatch = marker.name
          .toLowerCase()
          .includes(selectedName.toLowerCase());
      }

      if (
        mapType === "catAbuse" &&
        "incident" in marker &&
        selectedIncidentType &&
        selectedIncidentType !== "All"
      ) {
        incidentTypeMatch = marker.incident === selectedIncidentType;
      }
      return communityMatch && nameMatch && incidentTypeMatch;
    });

    const filteredCatIDs = filteredCatMarkers.map((marker) => marker.catId);

    Object.keys(libreMarkers).forEach((catId) => {
      if (filteredCatIDs.includes(catId)) {
        libreMarkers[catId].addTo(map.current!);
      } else {
        libreMarkers[catId].remove();
      }
    });

    if (selectedCommunity !== "All") {
      map.current?.fitBounds(
        getRegionBounds(selectedCommunity) as LngLatBoundsLike
      );
    } else {
      map.current?.fitBounds(SG_BOUNDS as LngLatBoundsLike);
    }

    setSelectedCatMarkers(filteredCatMarkers);
  };

  const render = () => {
    switch (state) {
      case State.LOADING:
        return <Loading />;
      case State.LOADED:
        return (
          <>
            <div className="new-map-filter-wrapper">
              <p
                className="new-map-filter-button"
                onClick={() => setShowFilter(!showFilter)}
              >
                Filter
              </p>
              <form
                className={
                  showFilter
                    ? "new-map-filter new-map-filter-show"
                    : "new-map-filter"
                }
                method="get"
                onSubmit={applyFilter}
              >
                <div className="new-map-filter-row">
                  <p className="new-map-filter-label">Name</p>
                  <input
                    className="new-map-filter-input"
                    type="text"
                    value={selectedName}
                    onChange={(e) => setSelectedName(e.target.value)}
                  />
                </div>
                <div className="new-map-filter-row">
                  <p className="new-map-filter-label">Region</p>
                  <select
                    className="new-map-filter-select"
                    value={selectedCommunity}
                    onChange={(e) => setSelectedCommunity(e.target.value)}
                  >
                    <option value={"All"}>All</option>
                    {SG_REGION_AREA_DATA.map((entry) => {
                      return (
                        <option key={entry.pln_area_n} value={entry.pln_area_n}>
                          {toTitleCase(entry.pln_area_n)}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {mapType === "catAbuse" ? (
                  <div className="new-map-filter-row">
                    <p className="new-map-filter-label">Incident Type</p>
                    <select
                      className="new-map-filter-select"
                      value={selectedIncidentType}
                      onChange={(e) => {
                        setSelectedIncidentType(e.target.value);
                      }}
                    >
                      <option value={"All"}>All</option>
                      {incidentType.map((incident) => {
                        return (
                          <option value={incident} key={incident}>
                            {incident}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                ) : null}
                <div className="new-map-filter-buttons">
                  <button
                    type="submit"
                    onClick={applyFilter}
                    className="new-map-filter-submit"
                  >
                    Apply
                  </button>
                  <button
                    type="reset"
                    onClick={resetFilter}
                    className="new-map-filter-submit"
                  >
                    Reset
                  </button>
                </div>
              </form>
            </div>
            <p className="new-map-numbers">
              {`Showing ${selectedCatMarkers.length}/${catMarkers.length} cats.`}
            </p>
          </>
        );
      case State.ERROR:
        return <SiteError />;
      default:
        return null;
    }
  };

  return (
    <>
      <div
        className={
          !selectedCat
            ? hidden
              ? "new-map-outer-wrapper new-map-hidden"
              : "new-map-outer-wrapper"
            : "new-map-none"
        }
      >
        {render()}
        <div
          className={
            state === State.LOADED && !hidden
              ? "new-map-container"
              : "new-map-container new-map-hidden"
          }
        >
          <div ref={mapContainer} className="new-map-wrapper" id="mapId">
            <Popup mapType={mapType} />
          </div>
        </div>
        <p
          id={mapType === "cats" ? "mapActionId" : "abusedMapActionId"}
          className="new-map-page-action"
        >
          {"Want to submit a community cat? "}
          <Link to="/submit">
            <span className="new-map-page-action-click">Click here</span>
          </Link>
          .
        </p>
      </div>
      <div
        className={
          state !== State.LOADED || hidden ? "new-map-cat-detail-hidden" : ""
        }
      >
        <div
          className="new-map-cat-detail-wrapper"
          id={mapType === "cats" ? "catDetailId" : "abusedCatDetailId"}
        >
          {selectedCat && Object.keys(selectedCat).length > 0 ? (
            <CatDetail
              catData={selectedCat}
              mapType={mapType}
              backFunction={backFunction}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default NewMap;
