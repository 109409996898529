// import ImageWithCaption from "../ui/ImageWithCaption";
import "./HelpPage.css";
// import catImage1 from "../../assets/other/cat-diagram.jpeg";
// import catImage2 from "../../assets/cats/stray-cat-30.jpeg";
import Collapsible from "../ui/Collapsible";
import Sterilise from "../ui/Sterilise";
import Report from "../ui/Report";
import Donate from "../ui/Donate";
import Feed from "../ui/Feed";

const HelpPage: React.FC = () => {
  return (
    <div className="help-page-wrapper">
      <div className="help-page-content">
        <h1 className="help-page-header">How can I help?</h1>
        <Collapsible header="Sterilise a cat" content={<Sterilise />} />
        <Collapsible header="Report abuse / abandonment" content={<Report />} />
        <Collapsible header="Donate" content={<Donate />} />
        <Collapsible header="Feeding Cats" content={<Feed />} />
      </div>
    </div>
  );
};

export default HelpPage;
