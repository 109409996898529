import "./SiteError.css";
import catImage from "../../assets/cats/stray-cat-4.jpg";

const SiteError: React.FC = () => {
  return (
    <div className="site-error-wrapper">
      <h1 className="site-error-headline">Something went wrong!</h1>

      <img className="site-error-image" src={catImage} alt="sad cat"></img>

      <p className="site-error-instructions">Please try again later.</p>
    </div>
  );
};

export default SiteError;
