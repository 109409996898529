import { CatInterface } from "./Marker";
import { useEffect, useState } from "react";
import axios from "axios";
import "./NewMapPopup.css";
import catImage from "../../assets/cats/stray-cat-4.jpg";
import Loading from "../ui/Loading";
import catLoadingGif from "../../assets/other/cat-loading-gif.gif";
import { REACT_APP_CAT_API_URL } from "../../util/config";
import { IncidentType } from "./NewMap";

interface PopupInterface {
  catId: string;
  onClickScroll: (catData: CatInterface | AbusedCatInterface) => void;
  mapType: "cats" | "catAbuse";
}

export interface AbusedCatInterface {
  name: string;
  sex: string;
  imageUrl: string;
  imageName: string;
  catId: string;
  region: string;
  likes?: string;
  dislikes?: string;
  personality?: string;
  other?: string;
  dateCreated: number;
  dateModified: number;
  incident: IncidentType;
  incidentDescription?: string;
  incidentSocialLink?: string;
  graphicImage: boolean;
}

enum State {
  LOADING,
  LOADED,
  ERROR,
}

const NewMapPopup: React.FC<PopupInterface> = ({
  catId,
  onClickScroll,
  mapType,
}) => {
  const [state, setState] = useState<State>(State.LOADING);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [catData, setCatData] = useState<CatInterface | AbusedCatInterface>();
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    if (!(process.env.REACT_APP_CAT_API_URL || REACT_APP_CAT_API_URL)) {
      setState(State.ERROR);
    }
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_CAT_API_URL || REACT_APP_CAT_API_URL}/api/${
          mapType === "cats" ? "cats" : "abusedCats"
        }/${catId}`
      )
      .then((res) => {
        setCatData(res.data);
        if (res.data?.graphicImage) {
          setShowImage(false);
        } else {
          setShowImage(true);
        }
        setState(State.LOADED);
      })
      .catch((e) => {
        setState(State.ERROR);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const render = () => {
    switch (state) {
      case State.LOADING:
        return (
          <div className="new-map-popup-loading-wrapper">
            <Loading />
          </div>
        );
      case State.LOADED:
        return (
          <div className="new-map-popup-content-wrapper">
            <div className="new-map-popup-content">
              <h1 className="new-map-popup-content-name">{catData?.name}</h1>
              {mapType === "catAbuse" ? (
                <p className="new-map-popup-content-abuse">
                  {(catData as AbusedCatInterface)?.incident}
                </p>
              ) : null}

              <div className="new-map-popup-content-link">
                <span onClick={() => onClickScroll(catData!)}>view more</span>
              </div>
              <div className="new-map-popup-image-wrapper">
                <img
                  src={catLoadingGif}
                  className="new-map-popup-loading-image"
                  hidden={imageLoaded}
                  alt="running cat"
                />
                <img
                  className={
                    showImage
                      ? "new-map-popup-image"
                      : "new-map-popup-image new-map-popup-image-blur"
                  }
                  src={catData?.imageUrl}
                  hidden={!imageLoaded}
                  onLoad={() => {
                    setImageLoaded(true);
                  }}
                  // onClick={() => setShowImage(true)}
                  onError={() => setState(State.ERROR)}
                  alt="community cat"
                />
                <span
                  className={
                    showImage || !imageLoaded
                      ? "new-map-popup-image-warning new-map-popup-hidden"
                      : "new-map-popup-image-warning"
                  }
                  onClick={() => setShowImage(true)}
                >
                  Graphic content: click to view.
                </span>
              </div>
            </div>
          </div>
        );
      case State.ERROR:
        return (
          <div className="new-map-popup-content-wrapper">
            <div className="new-map-popup-content">
              <h1 className="new-map-popup-error-headline">
                Something went wrong!
              </h1>

              <img
                className="new-map-popup-error-image"
                src={catImage}
                alt="sad cat"
              />

              <p className="new-map-popup-error-instructions">
                Please try again later.
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return render();
};

export default NewMapPopup;
