import "./App.css";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import HomePage from "./components/pages/HomePage";
import NewMapPage from "./components/pages/NewMapPage";
import FormPage from "./components/pages/FormPage";
import AboutPage from "./components/pages/AboutPage";
import UnknownPage from "./components/pages/UnknownPage";
import FaqPage from "./components/pages/FaqPage";
import { Helmet } from "react-helmet";
import HelpPage from "./components/pages/HelpPage";
import StrugglePage from "./components/pages/StrugglePage";
import Sterilise from "./components/ui/Sterilise";
import BaseContentPage from "./components/pages/BaseContentPage";
import Donate from "./components/ui/Donate";
import Feed from "./components/ui/Feed";
import Report from "./components/ui/Report";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route
          index
          element={
            <>
              <Helmet>
                <title>Cats of Singapore</title>
                <link rel="canonical" href="https://catsofsg.com" />
                <meta
                  name="description"
                  content="Advocating for community cats in Singapore."
                />
              </Helmet>
              <HomePage />
              <AboutPage />
              <StrugglePage />
              <HelpPage />
            </>
          }
        />
        <Route
          path="map"
          element={
            <>
              <Helmet>
                <title>Map — Cats of SG</title>
                <link rel="canonical" href="https://catsofsg.com/map" />
                <meta
                  name="description"
                  content="View the stray cats that reside in different communities in Singapore!"
                />
              </Helmet>
              <NewMapPage />
              <FaqPage />
            </>
          }
        />
        <Route
          path="submit"
          element={
            <>
              <Helmet>
                <title>Submit — Cats of SG</title>
                <link rel="canonical" href="https://catsofsg.com/submit" />
                <meta
                  name="description"
                  content="Submit a new community cat!"
                />
              </Helmet>
              <FormPage />
            </>
          }
        />
        <Route
          path="about"
          element={
            <>
              <Helmet>
                <title>About — Cats of SG</title>
                <link rel="canonical" href="https://catsofsg.com/about" />
                <meta
                  name="description"
                  content="Community cats are cats that reside in public spaces across Singapore. They tend to roam within a fixed neighbourhood where they are cared for by volunteer feeders. A good way to identify a community cat is to check if its left ear has been tipped. This signifies that the cat has been sterilised, often as part of a Trap-Neuter-Return-Manage Program."
                />
              </Helmet>
              <AboutPage />
              <StrugglePage />
              <HelpPage />
            </>
          }
        />
        <Route
          path="struggles"
          element={
            <>
              <Helmet>
                <title>Struggles — Cats of SG</title>
                <link rel="canonical" href="https://catsofsg.com/struggles" />
                <meta
                  name="description"
                  content="Community cats are at risk of being attacked by other animals, such as dogs or snakes. As cats are highly territorial, 
                  cats that are unfamiliar with one another may also fight, resulting in injuries and the 
                  transmission of diseases like Feline Immunodeficiency Virus (FIV) and Feline Leukemia Virus (FeLV)."
                />
              </Helmet>
              <StrugglePage />
              <HelpPage />
            </>
          }
        />
        {/* <Route path="cats/:catId" element={<DetailPage />} />
        <Route path="abusedCats/:catId" element={<AbusedDetailPage />} /> */}
        <Route
          path="help"
          element={
            <>
              <Helmet>
                <title>Help — Cats of SG</title>
                <link rel="canonical" href="https://catsofsg.com/help" />
                <meta
                  name="description"
                  content="Sterilisation is a humane way of managing the community cat population
                  in Singapore. It also eliminates or lowers the risk of certain forms of
                  cancer and diseases. Sterilisation is a one-off procedure that
                  will last for the cat’s lifetime, and is much cheaper than raising and
                  caring for a litter of kittens"
                />
              </Helmet>
              <HelpPage />
            </>
          }
        />
        <Route
          path="sterilise"
          element={
            <>
              <Helmet>
                <title>Sterilise — Cats of SG</title>
                <link rel="canonical" href="https://catsofsg.com/help" />
                <meta
                  name="description"
                  content="Sterilisation is a humane way of managing the community cat population
                  in Singapore. It also eliminates or lowers the risk of certain forms of
                  cancer and diseases. Sterilisation is a one-off procedure that
                  will last for the cat’s lifetime, and is much cheaper than raising and
                  caring for a litter of kittens"
                />
              </Helmet>
              <BaseContentPage header="Sterilise Community Cats">
                <Sterilise />
              </BaseContentPage>
            </>
          }
        />
        <Route
          path="donate"
          element={
            <>
              <Helmet>
                <title>Donate — Cats of SG</title>
                <link rel="canonical" href="https://catsofsg.com/donate" />
                <meta
                  name="description"
                  content="Community cats in Singapore are predominantly cared for by animal
                  welfare groups and volunteer caregivers, all of whom rely heavily on
                  public donations. It typically costs a few hundred dollars to rescue and
                  rehome a single cat. By donating to
                  welfare organisations and caregivers, you help ensure that they can
                  continue caring for community cats in Singapore."
                />
              </Helmet>
              <BaseContentPage header="Donate">
                <Donate />
              </BaseContentPage>
            </>
          }
        />
        <Route
          path="report"
          element={
            <>
              <Helmet>
                <title>Report — Cats of SG</title>
                <link rel="canonical" href="https://catsofsg.com/report" />
                <meta
                  name="description"
                  content="Individuals who are found guilty of failure in duty of care to their
                  pets (including pet abandonment) or committing acts of animal cruelty
                  can be charged under the Animals and Birds Act. First-time offenders who
                  fail in the duty of care to their pets can face a maximum fine of
                  $10,000 or a jail term of up to 12 months, or both."
                />
              </Helmet>
              <BaseContentPage header="Report Abuse">
                <Report />
              </BaseContentPage>
            </>
          }
        />
        <Route
          path="Feed"
          element={
            <>
              <Helmet>
                <title>Feed — Cats of SG</title>
                <link rel="canonical" href="https://catsofsg.com/feed" />
                <meta
                  name="description"
                  content="The act of feeding cats irresponsibly and not cleaning up
                  afterwards is known as ghost feeding. While your intentions may
                  be good, ghost feeding increases the work of volunteer
                  caregivers, who have to clean up after your mess. In addition,
                  it also leads to an increase in complaints made about community
                  cats, which could lead to them being forcefully captured or
                  relocated."
                />
              </Helmet>
              <BaseContentPage header="Feeding Community Cats">
                <Feed />
              </BaseContentPage>
            </>
          }
        />
        <Route path="*" element={<UnknownPage />} />
      </Routes>
    </div>
  );
}

export default App;
