import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import AbuseForm from "../form/AbuseForm";
import Form from "../form/Form";
// import PauseForm from "../ui/PauseForm";
import Toggle from "../ui/Toggle";
import "./FormPage.css";

const FormPage: React.FC = () => {
  const [formType, setFormType] = useState<string>("cats");
  const [searchParams, setSearchParams] = useSearchParams({
    mapType: formType,
  });

  const toggleMapType = (mapType: "cats" | "catAbuse") => {
    setSearchParams({ mapType });
  };

  useEffect(() => {
    if (searchParams.get("mapType") === "catAbuse") {
      setFormType("catAbuse");
    } else {
      setFormType("cats");
    }
  }, [searchParams]);

  return (
    <div className="form-page-wrapper">
      <div className="form-page-content">
        {/* <PauseForm /> */}
        <Toggle
          leftToggleClick={() => toggleMapType("cats")}
          rightToggleClick={() => toggleMapType("catAbuse")}
        />

        <>
          <div className="form-page-text">
            <p className="form-page-header">
              {formType === "cats" ? "Cat Map" : "Abuse Map"}
              {" Submission"}
            </p>
            <div className="form-page-notice">
              {formType === "cats"
                ? "Use this form to submit a community cat. "
                : "Use this form to submit a community cat that has experienced abuse, injury, or other forms of suffering as a result of being a stray. "}
              Please note the following:
              <ul className="form-page-notice-list">
                <li>
                  Ensure that the cat does not already exist in{" "}
                  <span className="form-page-link">
                    <Link to="/map">our map</Link>
                  </span>
                  .
                </li>
                <li>
                  If you wish to modify the data of an existing cat,{" "}
                  <a
                    className="form-page-link"
                    href="mailto:catsofsg.contact@gmail.com"
                  >
                    contact us
                  </a>{" "}
                  instead.
                </li>
                <li>Do not reveal the precise location of the cat.</li>
                <li>
                  Do not include any inappropriate, offensive, or illegal
                  content.
                </li>
                <li>
                  We encourage you to submit as much information as you can.
                </li>
              </ul>
            </div>
          </div>
          <Form hidden={formType === "catAbuse"} />
          <AbuseForm hidden={formType === "cats"} />
        </>
      </div>
    </div>
  );
};

export default FormPage;
