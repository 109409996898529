import mapImage from "../../assets/other/cat map.png";
import Button from "../ui/Button";
import "./HomePage.css";

const HomePage: React.FC = () => {
  return (
    <div className="home-wrapper">
      <div className="home-content">
        <h1 className="home-header">Cats of Singapore</h1>
        <h2 className="home-headline">
          Advocating for community cats in Singapore
        </h2>
        <img
          src={mapImage}
          alt="outline of Singapore filled with cats"
          className="home-image"
        />
        <div className="home-button">
          <Button internalNav={true} navTo="/map" buttonText="Go to Map" />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
