import { useState } from "react";
import "./Popup.css";
import closeIcon from "../../assets/icons/close-menu.png";

interface PopupInterface {
  mapType: "cats" | "catAbuse";
}

const Popup: React.FC<PopupInterface> = ({ mapType }) => {
  const [togglePopup, setTogglePopup] = useState<Boolean>(true);

  return (
    <div className={togglePopup ? "popup-wrapper" : "popup-wrapper popup-hide"}>
      <img
        className="popup-close-icon"
        onClick={() => setTogglePopup(false)}
        src={closeIcon}
        alt="close"
      />
      <div className="popup-content">
        <h1 className="popup-header">
          {mapType === "cats"
            ? "We do not show the exact location of any cat"
            : "Content Warning"}
        </h1>
        {mapType === "cats" ? (
          <span className="popup-description">
            For each cat, our platform generates and displays a random location
            within their{" "}
            <a
              className="popup-link"
              rel="noreferrer noopener"
              href="https://en.wikipedia.org/wiki/Regions_of_Singapore"
              target="_blank"
            >
              broader region
            </a>
            . This ensures that our map does not reveal the precise location of
            any cat, which could compromise their safety.{" "}
            <span
              className="popup-link"
              onClick={() =>
                window.scrollTo({ top: 10000, behavior: "smooth" })
              }
            >
              Learn more
            </span>
            .
          </span>
        ) : (
          <span className="popup-description">
            This map may contain descriptions and images of cat abuse, injury,
            illness, and death.
          </span>
        )}
      </div>
    </div>
  );
};

export default Popup;
