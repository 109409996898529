import "./SiteSuccess.css";
import catImage from "../../assets/cats/stray-cat-4.jpg";
import { Link } from "react-router-dom";

const SiteSuccess: React.FC = () => {
  return (
    <div className="site-success-wrapper">
      <h1 className="site-success-headline">
        We have recieved your submission!
      </h1>

      <img className="site-success-image" src={catImage} alt="happy cat"></img>

      <p className="site-success-instructions">
        Please give us up to 5 working days to approve it.
      </p>

      <div className="site-success-link">
        <Link to="/map">Back to map</Link>
      </div>
    </div>
  );
};

export default SiteSuccess;
