export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};

export const formatPara = (str: string) => {
  if (["-", "—", "–"].includes(str.charAt(0))) {
    return "\n" + str.charAt(0).toUpperCase() + str.substring(1);
  }
  return str.charAt(0).toUpperCase() + str.substring(1);
};
