import "./BaseContentPage.css";

interface BaseContentProps {
  header: string;
  children?: JSX.Element;
}

const BaseContentPage: React.FC<BaseContentProps> = ({ header, children }) => {
  return (
    <div className="base-content-wrapper">
      <div className="base-content-content">
        <h1 className="base-content-header">{header}</h1>
        {children}
      </div>
    </div>
  );
};

export default BaseContentPage;
