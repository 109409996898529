import "./Feature.css";

interface FeatureProps {
  header: string;
  text: JSX.Element;
  flipped: boolean;
  imagePath: string;
  imageCredit: string;
  imageAlt: string;
}

const Feature: React.FC<FeatureProps> = ({
  header,
  text,
  flipped,
  imagePath,
  imageCredit,
  imageAlt,
}) => {
  return (
    <>
      <div className={flipped ? "feature-wrapper" : "feature-wrapper-reversed"}>
        <div className="feature-image-wrapper">
          <img alt={imageAlt} className="feature-image" src={imagePath} />
          <span className="feature-image-credit">{imageCredit}</span>
        </div>
        <div className="feature-text-wrapper">
          <h2 className="feature-header">{header}</h2>
          <>{text}</>
        </div>
      </div>
      <div className="feature-wrapper-mobile">
        <h2 className="feature-header">{header}</h2>
        <div className="feature-image-wrapper">
          <img alt={imageAlt} className="feature-image" src={imagePath} />
          <span className="feature-image-credit">{imageCredit}</span>
        </div>
        <>{text}</>
      </div>
    </>
  );
};

export default Feature;
