import "../pages/HelpPage.css";

const Donate: React.FC = () => {
  return (
    <>
      <p className="help-page-text help-page-red">
        <a
          className="help-page-underline"
          href="https://gogetfunding.com/help-save-community-cat-boy-from-liver-infection"
          rel="noreferrer noopener"
          target="_blank"
        >
          [Link to fundraiser] Give Boy a 2nd Chance: Help this Community Cat
          Fight Liver Infection!
        </a>
      </p>
      <br />
      <h3 className="help-page-subheader">Why donate?</h3>
      <p className="help-page-text">
        Community cats in Singapore are predominantly cared for by animal
        welfare groups and volunteer caregivers, all of whom rely heavily on
        public donations. It typically costs a few hundred dollars to rescue and
        rehome a single cat. However, if the cat requires medical attention,
        this cost can easily exceed a few thousand dollars. By donating to
        welfare organisations and caregivers, you help ensure that they can
        continue caring for community cats in Singapore.
      </p>
      <br />
      <h3 className="help-page-subheader">How can I donate?</h3>
      <p className="help-page-text">
        You may donate to an animal welfare organisation or shelter by visiting
        their website. We have provided links to some organisations below:
        <ul>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.catwelfare.org/donations/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Cat Welfare Society
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://spca.org.sg/donate/"
              rel="noreferrer noopener"
              target="_blank"
            >
              SPCA
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.causesforanimals.com/donations--support.html"
              rel="noreferrer noopener"
              target="_blank"
            >
              Causes for Animals
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.project-luni.com/get-involved/donate/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Project LUNI
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://w.ahasg.com/donations.html"
              rel="noreferrer noopener"
              target="_blank"
            >
              Animal Human Alliance
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.kittensanctuarysg.org/donate"
              rel="noreferrer noopener"
              target="_blank"
            >
              Kitten Sanctuary Singapore
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.simplygiving.com/appeal/lovingthestrays2023"
              rel="noreferrer noopener"
              target="_blank"
            >
              Love Kuching Project
            </a>
          </li>
        </ul>
      </p>
      <br />
      <p className="help-page-text">
        You could also support the efforts of cat caregivers in a community near
        you. More information about their work can often be found on Facebook
        groups. We have provided links to some community Facebook groups below:
        <ul>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/groups/138769789508562"
              rel="noreferrer noopener"
              target="_blank"
            >
              General
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/profile.php?id=100067924567317"
              rel="noreferrer noopener"
              target="_blank"
            >
              Ang Mo Kio
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/groups/1095502927952627"
              rel="noreferrer noopener"
              target="_blank"
            >
              Bedok
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/BBcommcat"
              rel="noreferrer noopener"
              target="_blank"
            >
              Bukit Batok
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/groups/158749984955518"
              rel="noreferrer noopener"
              target="_blank"
            >
              Canberra
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/groups/236262061270216"
              rel="noreferrer noopener"
              target="_blank"
            >
              Clementi / West Coast
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/groups/1697925417085399/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Chua Chu Kang / Yew Tee
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/singaporecatfeedersCentralCBDChinatownTanjongPagar"
              rel="noreferrer noopener"
              target="_blank"
            >
              Chinatown / Tanjong Pagar
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/groups/452874399223431/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Hougang
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/groups/2971933339494772/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Jurong
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/kallangcats.meowing"
              rel="noreferrer noopener"
              target="_blank"
            >
              Kallang
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/catsofmarineterrace/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Marine Terrace
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/groups/pasirriscats/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Pasir Ris
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/groups/2226324094343052/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Punggol
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/RedhillMeows"
              rel="noreferrer noopener"
              target="_blank"
            >
              Redhill
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/groups/620438408568802"
              rel="noreferrer noopener"
              target="_blank"
            >
              Simei
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/groups/160007400020"
              rel="noreferrer noopener"
              target="_blank"
            >
              Tampines
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/groups/kimkeatcats/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Toa Payoh / Kim Keat
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/groups/686244872219271/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Woodlands
            </a>
          </li>
          <li className="help-page-list-item">
            <a
              className="help-page-underline"
              href="https://www.facebook.com/326tabby"
              rel="noreferrer noopener"
              target="_blank"
            >
              Yishun
            </a>
          </li>
        </ul>
      </p>
    </>
  );
};

export default Donate;
