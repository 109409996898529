import "./Loading.css";

import catLoadingGif from "../../assets/other/cat-loading-gif.gif";

const Loading: React.FC = () => {
  return (
    <img className="loading-image" src={catLoadingGif} alt="running cat" />
  );
};

export default Loading;
