import "../pages/HelpPage.css";

const Sterilise: React.FC = () => {
  return (
    <>
      <h3 className="help-page-subheader">Why sterilise cats?</h3>
      <p className="help-page-text">
        Sterilisation is a humane way of managing the community cat population
        in Singapore. It also eliminates or lowers the risk of certain forms of
        cancer and diseases. After sterilisation, the cat is usually released
        back to where it was found. Sterilisation is a one-off procedure that
        will last for the cat’s lifetime, and is much cheaper than raising and
        caring for a litter of kittens. By helping to sterilise a cat, you will
        be improving the welfare of community cats in Singapore!
      </p>
      <br />
      <h3 className="help-page-subheader">How do I help?</h3>
      <p className="help-page-text">
        If you come across an unsterilised cat, you can help get the cat
        sterilised. You can identify an unsterilised cat by looking at their
        ears. If their left ear is tipped (a small portion of the ear missing at
        the tip), or their right ear is tattood, they are likely to be
        sterilised.
      </p>
      <br />
      <h3 className="help-page-subheader">What are the steps?</h3>
      <ol>
        <li className="help-page-list-item">
          Take a picture of the unsterilised cat(s), and note down their
          details. E.g. where they can be found, how young they look, whether
          they are scared of you, whether they look healthy, and if there are
          any food bowls or feeders nearby.
        </li>
        <li className="help-page-list-item">
          Approach animal welfare organisations for assistance. The Stray Cat
          Sterilisation Programme (SCSP) was launched in 2011. Under this
          programme, free-roaming cats are trapped by Animal Welfare Groups
          (AWGs) and cat caregivers, sterilised and microchipped by
          veterinarians, and returned back to the original site to live out
          their lives naturally. Some of the participating AWGs partners for the
          SCSP include:
        </li>
      </ol>
      <br />
      <div className="help-page-table">
        <div className="help-page-table-data help-page-table-border-first">
          <h3 className="help-page-underline">Cat Welfare Society (CWS)</h3>
          <ol>
            <li className="help-page-list-item">
              Fill up the sterilisation form on the CWS website.
            </li>
            <li className="help-page-list-item">
              Trap the cat humanely, either by yourself or with the help of a
              professional trapper.
            </li>
            <li className="help-page-list-item">
              CWS normally covers the cost of sterilisation. The cost of
              trapping, transport, and post-op boarding are only covered on a
              case-by-case basis, and may have to be borne by you.
            </li>
            <li className="help-page-list-item">
              More details can be found{" "}
              <a
                className="help-page-underline"
                href="https://www.catwelfare.org/start-your-own-trap-and-neuter/"
                rel="noreferrer noopener"
                target="_blank"
              >
                here
              </a>{" "}
              and{" "}
              <a
                className="help-page-underline"
                href="https://www.catwelfare.org/catsnip/"
                rel="noreferrer noopener"
                target="_blank"
              >
                here
              </a>
              .
            </li>
          </ol>
        </div>
        <div className="help-page-table-data help-page-table-border-second">
          <h3 className="help-page-underline">Causes For Animals (CAS)</h3>
          <ol>
            <li className="help-page-list-item">
              Email info@causesforanimals.com. Include your name, handphone
              number, cat location, and cat details.
            </li>
            <li className="help-page-list-item">
              After CAS replies your email, book a trapper to trap the cat. Some
              trappers that CAS works with include Damy (93390481) and Mark
              (90918901).
            </li>
            <li className="help-page-list-item">
              The trappers that CAS works with will help transport the cat to
              and from the clinic.
            </li>
            <li className="help-page-list-item">
              While CAS will cover the cost of sterilisation and medical care,
              you must cover the cost of trapping. If you need financial
              assistance, you may email CAS.
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default Sterilise;
