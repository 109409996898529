import "../pages/HelpPage.css";

const Report: React.FC = () => {
  return (
    <>
      <h3 className="help-page-subheader">Why report abuse?</h3>
      <p className="help-page-text">
        By reporting cat abuse in a responsible manner, you can help AVS in
        investigating and taking action against offenders. This will help deter
        repeat offenders and reduce abuse rates in the long run.
      </p>
      <br />
      <p className="help-page-text">
        Individuals who are found guilty of failure in duty of care to their
        pets (including pet abandonment) or committing acts of animal cruelty
        can be charged under the Animals and Birds Act. First-time offenders who
        fail in the duty of care to their pets can face a maximum fine of
        $10,000 or a jail term of up to 12 months, or both. First-time offenders
        who commit animal cruelty-related offences can face a maximum fine of
        $15,000, or a jail term of up to 18 months, or both.
      </p>
      <br />
      <h3 className="help-page-subheader">How can I report?</h3>
      <p className="help-page-text">
        If you witness a cat being abandoned or any act of cruelty resulting in
        injury or death, you may do the following:
      </p>
      <ol>
        <li className="help-page-list-item">
          Immediately contact AVS via their website at{" "}
          <a
            className="help-page-underline"
            href="https://avs.gov.sg/feedback"
            rel="noreferrer noopener"
            target="_blank"
          >
            www.avs.gov.sg/feedback
          </a>{" "}
          or via their Animal Response Centre at 1800-476-1600, or SPCA’s 24/7
          hotline, 6287 5355 Ext. 9.
        </li>
        <li className="help-page-list-item">
          Take a picture or video of the suspect if it is safe to do so. Note
          any distinguishing features of the person and clothing/accessories
          worn or vehicle numbers.
        </li>
        <li className="help-page-list-item">
          If the animal is dead, do not move the body. Note the exact location
          (street name/bus stop number/lamp post number etc.). Take a picture of
          the animal and the surrounding area.
        </li>
        <li className="help-page-list-item">
          Send all images/videos to AVS at{" "}
          <a
            className="help-page-underline"
            href="https://avs.gov.sg/feedback"
            rel="noreferrer noopener"
            target="_blank"
          >
            www.avs.gov.sg/feedback
          </a>{" "}
          or email SPCA at{" "}
          <a
            className="help-page-underline"
            href="mailto:inspector@spca.org.sg"
            rel="noreferrer noopener"
            target="_blank"
          >
            inspector@spca.org.sg
          </a>
          .
        </li>
      </ol>
      <br />
      <p className="help-page-text">
        If you witnessed any other type of cruelty, you may fill up SPCA’s
        cruelty complaint form. More details can be found{" "}
        <a
          className="help-page-underline"
          href="https://spca.org.sg/services/cruelty-welfare-investigations/"
          rel="noreferrer noopener"
          target="_blank"
        >
          here
        </a>
        .
      </p>
    </>
  );
};

export default Report;
