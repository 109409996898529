import "./NewMapPage.css";
import NewMap from "../map/NewMap";
import { useEffect, useState } from "react";
import Toggle from "../ui/Toggle";
import { useSearchParams } from "react-router-dom";

const NewMapPage: React.FC = () => {
  const [mapType, setMapType] = useState<"cats" | "catAbuse">("cats");
  const [searchParams, setSearchParams] = useSearchParams({ mapType });

  const toggleMapType = (mapType: "cats" | "catAbuse") => {
    setSearchParams({ mapType });
  };

  useEffect(() => {
    if (searchParams.get("mapType") === "catAbuse") {
      setMapType("catAbuse");
    } else {
      setMapType("cats");
    }
  }, [searchParams]);

  return (
    <div className="new-map-page-wrapper">
      <div className="new-map-page-content">
        <>
          <Toggle
            leftToggleClick={() => toggleMapType("cats")}
            rightToggleClick={() => toggleMapType("catAbuse")}
          />
          <div className="new-map-page-text">
            <p className="new-map-page-instructions">
              {mapType === "cats"
                ? "Community Cats"
                : "Abuse and Other Suffering"}
            </p>
            <p className="new-map-page-disclaimer">
              {/* <span className="new-map-page-disclaimer">
                {mapType === "cats"
                  ? "This map contains the identities of different community cats that live in Singapore!"
                  : "This map contains the identities of community cats that have experienced abuse, injury, or other forms of suffering."}
              </span> */}
              Note: To ensure the safety of our community cats, we do not
              collect or display their exact locations.{" "}
              <span
                className="new-map-page-action-click"
                onClick={() =>
                  window.scrollTo({ top: 10000, behavior: "smooth" })
                }
              >
                Learn more
              </span>
              .
            </p>
          </div>
          {/* {getMapType()} */}
          {/* {mapType === "cats" ? <NewMap /> : <AbuseMap />} */}
          <NewMap mapType="cats" hidden={mapType !== "cats"} />
          <NewMap mapType="catAbuse" hidden={mapType !== "catAbuse"} />
        </>
      </div>
    </div>
  );
};

export default NewMapPage;
