import { booleanPointInPolygon, polygon } from "@turf/turf";
import planningAreaData from "../assets/data/planningAreas.json";
import regionAreaData from "../assets/data/regionAreas.json";
// export const SG_CENTER_COORDS = new google.maps.LatLng({
//   lat: 1.352083,
//   lng: 103.819836,
// });
export const SG_CENTER_COORDS = [103.819836, 1.352083];

export const SG_PLANNING_AREA_DATA = planningAreaData.sort((a, b) =>
  a.pln_area_n.localeCompare(b.pln_area_n)
);

export const SG_REGION_AREA_DATA = regionAreaData.sort((a, b) =>
  a.pln_area_n.localeCompare(b.pln_area_n)
);

export const getRegionBounds = (community: string) => {
  const points: number[][][][] = JSON.parse(
    regionAreaData.find((entry) => entry.pln_area_n === community)!.geojson!
  ).coordinates;

  const coordinates: number[][] = points.flat(2);

  const lowLat = coordinates.reduce((prev, cur) => {
    if (cur[1] < prev) {
      return cur[1];
    } else {
      return prev;
    }
  }, 90);

  const highLat = coordinates.reduce((prev, cur) => {
    if (cur[1] > prev) {
      return cur[1];
    } else {
      return prev;
    }
  }, -90);

  const lowLng = coordinates.reduce((prev, cur) => {
    if (cur[0] < prev) {
      return cur[0];
    } else {
      return prev;
    }
  }, 180);

  const highLng = coordinates.reduce((prev, cur) => {
    if (cur[0] > prev) {
      return cur[0];
    } else {
      return prev;
    }
  }, -180);

  return [lowLng, lowLat, highLng, highLat];
};

export const SG_COORDS = [
  [103.6079407, 1.2180174],
  [103.607254, 1.2592065],
  [103.6134338, 1.2749955],
  [103.6168671, 1.2907844],
  [103.6381531, 1.3429556],
  [103.6532593, 1.3724731],
  [103.6608124, 1.3964987],
  [103.6759186, 1.420524],
  [103.7033844, 1.4383712],
  [103.7198639, 1.4500405],
  [103.7507629, 1.4349391],
  [103.7651825, 1.4411169],
  [103.7768555, 1.4500405],
  [103.791275, 1.4569047],
  [103.814621, 1.4678875],
  [103.8379669, 1.4623961],
  [103.8599396, 1.4479812],
  [103.8983917, 1.4150325],
  [103.9121246, 1.4191511],
  [103.9402771, 1.386202],
  [103.9643097, 1.380024],
  [103.9828491, 1.3903207],
  [103.9993286, 1.3875749],
  [104.0137482, 1.3656086],
  [104.031601, 1.3608034],
  [104.0158081, 1.3113784],
  [103.9821625, 1.3134378],
  [103.8874054, 1.2852926],
  [103.8352203, 1.2358661],
  [103.7995148, 1.2653848],
  [103.7885284, 1.2544011],
  [103.7514496, 1.2756819],
  [103.6752319, 1.2207633],
  [103.649826, 1.204974],
  [103.6106873, 1.2111525],
];

const SG_LOW_LAT = SG_COORDS.reduce((prev, cur) => {
  if (cur[1] < prev) {
    return cur[1];
  } else {
    return prev;
  }
}, 90);

const SG_HIGH_LAT = SG_COORDS.reduce((prev, cur) => {
  if (cur[1] > prev) {
    return cur[1];
  } else {
    return prev;
  }
}, -90);

const SG_LOW_LNG = SG_COORDS.reduce((prev, cur) => {
  if (cur[0] < prev) {
    return cur[0];
  } else {
    return prev;
  }
}, 180);

const SG_HIGH_LNG = SG_COORDS.reduce((prev, cur) => {
  if (cur[0] > prev) {
    return cur[0];
  } else {
    return prev;
  }
}, -180);

export const SG_BOUNDS = [SG_LOW_LNG, SG_LOW_LAT, SG_HIGH_LNG, SG_HIGH_LAT];

export const getRandomPoint = (community: string) => {
  const points: number[][][][] = JSON.parse(
    regionAreaData.find((entry) => entry.pln_area_n === community)!.geojson!
  ).coordinates;

  const coordinates: number[][] = points.flat(2);

  const lowLat = coordinates.reduce((prev, cur) => {
    if (cur[1] < prev) {
      return cur[1];
    } else {
      return prev;
    }
  }, 90);

  const highLat = coordinates.reduce((prev, cur) => {
    if (cur[1] > prev) {
      return cur[1];
    } else {
      return prev;
    }
  }, -90);

  const lowLng = coordinates.reduce((prev, cur) => {
    if (cur[0] < prev) {
      return cur[0];
    } else {
      return prev;
    }
  }, 180);

  const highLng = coordinates.reduce((prev, cur) => {
    if (cur[0] > prev) {
      return cur[0];
    } else {
      return prev;
    }
  }, -180);

  const latRange = highLat - lowLat;
  const lngRange = highLng - lowLng;
  const polygons = points.map((entry) => polygon(entry));
  let randomPoint = [-180, -180];

  var loopCount = 1;
  // eslint-disable-next-line no-loop-func
  while (!polygons.some((poly) => booleanPointInPolygon(randomPoint, poly))) {
    loopCount += 1;
    const randomLng = lowLng + lngRange * Math.random();
    const randomLat = lowLat + latRange * Math.random();
    randomPoint = [randomLng, randomLat];
    if (loopCount > 100) {
      throw new Error("Could not generate random point");
    }
  }

  return randomPoint;
};
