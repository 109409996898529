import axios from "axios";
import { useEffect, useState } from "react";
import "./Form.css";
import React, { useRef } from "react";
import { toTitleCase } from "../../util/stringUtil";
import ReCAPTCHA from "react-google-recaptcha";
import SiteError from "../ui/SiteError";
import Button from "../ui/Button";
import Loading from "../ui/Loading";
import SiteSuccess from "../ui/SiteSuccess";
import { SG_PLANNING_AREA_DATA } from "../../util/mapUtil";
import { REACT_APP_CAT_API_URL, REACT_APP_SITE_KEY } from "../../util/config";
import { incidentType, IncidentType } from "../map/NewMap";

interface FormInput {
  name: string | undefined;
  likes?: string | undefined;
  dislikes?: string | undefined;
  personality?: string | undefined;
  other?: string | undefined;
  community: string | undefined;
  file: Blob | undefined;
  sex: string | undefined;
  captcha: string | undefined;
  incident: IncidentType | undefined;
  incidentDescription?: string | undefined;
  incidentSocialLink?: string | undefined;
}

interface FormErrors {
  name: string | undefined;
  community: string | undefined;
  file: string | undefined;
  captcha: string | undefined;
  sex: string | undefined;
  incident: string | undefined;
}

enum FormState {
  DRAFT,
  SUBMITTED,
  SUCCESS,
  ERROR,
}

interface FormProps {
  hidden: boolean;
}

const AbuseForm: React.FC<FormProps> = ({ hidden }) => {
  const allowedFileTypes = ["image/png", "image/jpeg", "image/gif"];

  const captchaRef = useRef<ReCAPTCHA>(null);

  const [formState, setFormState] = useState<FormState>(FormState.DRAFT);

  const [formInput, setFormInput] = useState<FormInput>({
    name: undefined,
    likes: undefined,
    dislikes: undefined,
    personality: undefined,
    other: undefined,
    community: undefined,
    file: undefined,
    sex: undefined,
    captcha: undefined,
    incident: undefined,
    incidentDescription: undefined,
    incidentSocialLink: undefined,
  });

  const [formErrors, setFormErrors] = useState<FormErrors>({
    name: undefined,
    community: undefined,
    sex: undefined,
    file: undefined,
    captcha: undefined,
    incident: undefined,
  });

  useEffect(() => {
    if (
      !(process.env.REACT_APP_CAT_API_URL || REACT_APP_CAT_API_URL) ||
      !(process.env.REACT_APP_SITE_KEY || REACT_APP_SITE_KEY)
    ) {
      setFormState(FormState.ERROR);
    }
  }, []);

  const submitForm = () => {
    let errors: FormErrors = {
      ...formErrors,
    };

    const token = captchaRef.current?.getValue();
    if (!captchaRef.current || !token) {
      formInput.captcha = undefined;
    } else {
      formInput.captcha = token;
      captchaRef.current.reset();
    }

    for (const field in formErrors) {
      if (!formInput[field as keyof FormInput]?.toString().trim()) {
        errors[field as keyof FormErrors] = `- Missing ${field}.`;
      } else {
        errors[field as keyof FormErrors] = undefined;
      }
    }

    if (formInput.file && formInput.file.size > 5 * 1024 * 1024) {
      errors.file = "- File size cannot exceed 5mb.";
    }

    if (formInput.file && !allowedFileTypes.includes(formInput.file.type)) {
      errors.file = "- File type must be either jpeg, jpg, png, or gif.";
    }

    setFormErrors(errors);

    if (Object.keys(errors).some((key) => errors[key as keyof FormErrors])) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    setFormState(FormState.SUBMITTED);

    const formData = new FormData();

    for (const field in formInput) {
      if (formInput[field as keyof FormInput]) {
        formData.append(field, formInput[field as keyof FormInput]!);
      }
    }

    formData.append("dateCreated", Date.now().toString());
    formData.append("dateModified", Date.now().toString());
    axios
      .post(
        `${
          process.env.REACT_APP_CAT_API_URL || REACT_APP_CAT_API_URL
        }/api/abusedCats`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        if (res.status !== 200) {
          setFormState(FormState.ERROR);
        } else {
          setFormState(FormState.SUCCESS);
        }
      })
      .catch((err) => {
        setFormState(FormState.ERROR);
      });
  };

  const setFormInputHelper = (field: keyof FormInput, value: string | Blob) => {
    setFormInput({
      ...formInput,
      [field]: value,
    });
  };

  const render = () => {
    switch (formState) {
      case FormState.DRAFT:
        return (
          <form method="post" className="form-wrapper">
            <div className="form-errors">
              {Object.keys(formErrors).map((error) => {
                if (formErrors[error as keyof FormErrors]) {
                  return (
                    <p className="form-error" key={error}>
                      {formErrors[error as keyof FormErrors]}
                    </p>
                  );
                }
                return null;
              })}
            </div>
            <span className="form-section-header">Basic Information</span>
            <div className="form-row">
              <p className="form-label">Cat Name</p>
              <input
                className="form-input"
                type="text"
                value={formInput.name || ""}
                onChange={(e) => setFormInputHelper("name", e.target.value)}
              />
            </div>
            <div className="form-row">
              <span className="form-label">{"Community "}</span>
              <span className="form-label-description">
                This will not be displayed on the map. We use this information
                to detect duplicate submissions, as well as determine which{" "}
                <a
                  href="https://en.wikipedia.org/wiki/Regions_of_Singapore"
                  rel="noreferrer noopener"
                  target="_blank"
                  className="form-link"
                >
                  broad region
                </a>{" "}
                the cat belongs to.
              </span>
              <select
                defaultValue={"--select an option --"}
                className="form-select"
                value={formInput.community || ""}
                onChange={(e) =>
                  setFormInputHelper("community", e.target.value)
                }
              >
                <option value="" disabled>
                  -- select an option --
                </option>
                {SG_PLANNING_AREA_DATA.map((entry) => {
                  return (
                    <option key={entry.pln_area_n} value={entry.pln_area_n}>
                      {toTitleCase(entry.pln_area_n)}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-row">
              <p className="form-label">Sex</p>
              <select
                className="form-select"
                value={formInput.sex || ""}
                onChange={(e) => setFormInputHelper("sex", e.target.value)}
              >
                <option value="" disabled>
                  -- select an option --
                </option>
                <option>Male</option>
                <option>Female</option>
                <option>Unknown</option>
              </select>
            </div>
            {/* <hr className="form-horizontal-line" /> */}
            <br />
            <br />
            <br />
            <span className="form-section-header">Incident Details</span>
            <div className="form-row">
              <p className="form-label">Incident Type</p>
              <select
                className="form-select"
                value={formInput.incident || ""}
                onChange={(e) => setFormInputHelper("incident", e.target.value)}
              >
                <option value="" disabled>
                  -- select an option --
                </option>
                {incidentType.map((incident) => {
                  return (
                    <option value={incident} key={incident}>
                      {incident}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-row">
              <span className="form-label">
                {"Incident Description "}
                <span className="form-label-optional">(optional)</span>
              </span>
              <span className="form-label-description">
                You may include dates, descriptions of any injuries sustained,
                physical and emotional impacts, financial costs of treatment, or
                any other details.
              </span>
              <textarea
                className="form-input description-input"
                value={formInput.incidentDescription || ""}
                onChange={(e) =>
                  setFormInputHelper("incidentDescription", e.target.value)
                }
              />
            </div>
            <div className="form-row">
              <span className="form-label">
                {"Media Link "}
                <span className="form-label-optional">(optional)</span>
              </span>
              <span className="form-label-description">
                For missing cats or cats seeking medical funds, you may include
                a link to a public social media post that will give webstie
                visitors the appropriate contact information. Otherwise, you may
                include online articles, petitions, or any other link that gives
                more details about the incident.
              </span>
              <textarea
                className="form-input description-input"
                value={formInput.incidentSocialLink || ""}
                onChange={(e) =>
                  setFormInputHelper("incidentSocialLink", e.target.value)
                }
              />
            </div>
            <div className="form-row">
              <span className="form-label">
                Image{" "}
                <span className="form-label-optional">(jpeg/jpg/png/gif)</span>
              </span>
              <span className="form-label-description">
                For cats that sustained injuries, you may attach an image that
                highlights those injuries. Otherwise, you may attach any image
                of the cat. Please ensure that the image does not include any
                identifiers (e.g. surrounding buildings) that could reveal the
                location of the cat. Images that reveal such details may be
                blurred or cropped accordingly.
              </span>
              <input
                className="file-input"
                type="file"
                onChange={(e) => {
                  if (e.target.files) {
                    setFormInputHelper("file", e.target.files[0]);
                  }
                }}
              />
            </div>
            {/* <hr className="form-horizontal-line" /> */}
            <br />
            <br />
            <br />
            <span className="form-section-header">Other Cat Information</span>
            <div className="form-row">
              <span className="form-label">
                {"Likes "}
                <span className="form-label-optional">(optional)</span>
              </span>
              <textarea
                className="form-input description-input"
                value={formInput.likes || ""}
                onChange={(e) => setFormInputHelper("likes", e.target.value)}
              />
            </div>
            <div className="form-row">
              <span className="form-label">
                {"Dislikes "}
                <span className="form-label-optional">(optional)</span>
              </span>
              <textarea
                className="form-input description-input"
                value={formInput.dislikes || ""}
                onChange={(e) => setFormInputHelper("dislikes", e.target.value)}
              />
            </div>
            <div className="form-row">
              <span className="form-label">
                {"Personality "}
                <span className="form-label-optional">(optional)</span>
              </span>
              <textarea
                className="form-input description-input"
                value={formInput.personality || ""}
                onChange={(e) =>
                  setFormInputHelper("personality", e.target.value)
                }
              />
            </div>
            <div className="form-row">
              <span className="form-label">
                {"Other Information "}
                <span className="form-label-optional">(optional)</span>
              </span>
              <textarea
                className="form-input description-input"
                value={formInput.other || ""}
                onChange={(e) => setFormInputHelper("other", e.target.value)}
              />
            </div>
            <ReCAPTCHA
              className="form-captcha"
              sitekey={process.env.REACT_APP_SITE_KEY || REACT_APP_SITE_KEY}
              ref={captchaRef}
              size={"compact"}
            />
            <div className="form-submit-wrapper">
              <Button
                internalNav={false}
                onClick={submitForm}
                buttonText="Submit"
              />
            </div>
          </form>
        );
      case FormState.SUBMITTED:
        return <Loading />;
      case FormState.SUCCESS:
        return <SiteSuccess />;
      case FormState.ERROR:
        return <SiteError />;
      default:
        return null;
    }
  };

  return <div className={hidden ? "form-hidden" : ""}>{render()}</div>;
};

export default AbuseForm;
