import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "./Toggle.css";

interface ToggleProps {
  leftToggleClick: () => void;
  rightToggleClick: () => void;
}

const Toggle: React.FC<ToggleProps> = ({
  leftToggleClick,
  rightToggleClick,
}) => {
  const [selected, setSelected] = useState<string>("left");
  const onLeftClick = () => {
    // setSelected("left");
    leftToggleClick();
  };
  const onRightClick = () => {
    // setSelected("right");
    rightToggleClick();
  };
  const [searchParams] = useSearchParams({});

  useEffect(() => {
    if (searchParams.get("mapType") === "catAbuse") {
      setSelected("right");
    } else {
      setSelected("left");
    }
  }, [searchParams]);

  return (
    <div className="toggle-wrapper">
      <div
        className={
          selected === "left"
            ? "toggle-button toggle-button-left toggle-button-selected"
            : "toggle-button toggle-button-left"
        }
        onClick={onLeftClick}
      >
        Cat Map
      </div>
      <div
        className={
          selected === "right"
            ? "toggle-button toggle-button-right toggle-button-selected"
            : "toggle-button toggle-button-right"
        }
        onClick={onRightClick}
      >
        Abuse Map
      </div>
    </div>
  );
};

export default Toggle;
