import { NavLink } from "react-router-dom";
import MobileNavbar from "./MobileNavbar";
import "./Navbar.css";

const Navbar: React.FC = () => {
  return (
    <>
      <div className="navbar-wrapper">
        <div className="navbar-logo">
          <div className="navbar-button navbar-logo-button">
            <NavLink to="/">Cats of SG</NavLink>
          </div>
        </div>
        <div className="navbar-buttons-container">
          <div className="navbar-button">
            <NavLink to="/map">Map</NavLink>
          </div>
          <div className="navbar-button">
            <NavLink to="/about">About</NavLink>
          </div>
          <div className="navbar-button">
            <NavLink to="/struggles">Struggles</NavLink>
          </div>
          <div className="navbar-button">
            <NavLink to="/help">Help</NavLink>
          </div>
          <div className="navbar-button">
            <NavLink to="/submit">Submit</NavLink>
          </div>
        </div>
      </div>
      <MobileNavbar />
    </>
  );
};

export default Navbar;
