import "./AboutPage.css";
import Feature from "../ui/Feature";
import catImage1 from "../../assets/cats/stray-cat-1.jpeg";
import catImage2 from "../../assets/cats/stray-cat-23.jpeg";
import catImage4 from "../../assets/cats/stray-cat-29.jpeg";
import { Link } from "react-router-dom";

const AboutPage: React.FC = () => {
  return (
    <div className="about-page-wrapper">
      <div className="about-page-content">
        <div className="feature-container">
          <Feature
            header="Who are our community cats?"
            text={
              <span className="feature-text">
                Community cats are cats that reside in public spaces across
                Singapore. They tend to roam within a fixed neighbourhood where
                they are cared for by volunteer feeders. A good way to identify
                a community cat is to check if its left ear has been tipped.
                This signifies that the cat has been sterilised, often as part
                of a{" "}
                <a
                  className="feature-text-link"
                  rel="noreferrer noopener"
                  target="_blank"
                  href="https://www.catwelfare.org/sterilisation-overview/"
                >
                  Trap-Neuter-Return-Manage Program
                </a>
                .
              </span>
            }
            imagePath={catImage1}
            imageAlt="White and gray Singapore community cat sitting on a ledge with eyes closed."
            flipped={false}
            imageCredit="NUS CatCafé"
          />
          <Feature
            header="Are they friendly?"
            text={
              <span className="feature-text">
                Different community cats have different personalities, including
                how friendly they are to humans. If you wish to befriend a
                community cat, we reccommend you let them approach and sniff you
                first. If they show any signs of fear or aggression, including
                running away, hissing, or hiding, it is best to leave them alone
                and give them their space.
              </span>
            }
            imagePath={catImage2}
            imageAlt="Orange Singapore community cat lying down on its side."
            flipped={true}
            imageCredit="NUS CatCafé"
          />
          <Feature
            header="Can I feed them?"
            text={
              <span className="feature-text">
                If you wish to feed community cats, please do so in a
                responsible manner. Check for regular feeders first, use
                containers instead of placing cat food directly on the ground,
                and clean up afterwards. By ignoring these guidelines, you not
                only compromise the wellbeing of community cats, but also commit
                a crime by littering. More details about responsible cat feeding
                can be found{" "}
                <Link to="/feed">
                  <span className="feature-text-link">here</span>
                </Link>
              </span>
            }
            imagePath={catImage4}
            imageAlt="Orange Singapore community cat lying down on its side."
            flipped={false}
            imageCredit="NUS CatCafé"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
